<template>
  <div id="payrolls">
    <v-container>
      <v-layout row wrap justify="start">
        <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
          <v-btn
            style="float: left"
            outlined
            small
            :color="$store.state.secondaryColor"
            @click="goBack"
          >
            <v-icon small left>mdi-arrow-left</v-icon>
            <span class="caption">{{ $t("back") }}</span>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm4 md4 lg4 class="text-right px-1 mt-1">
          <v-autocomplete
            dense
            solo
            class="caption"
            :label="$t('select month')"
            item-value="id"
            :items="months"
            v-model="selectedMonth"
            @change="onSelectMonth"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 sm4 md4 lg4 class="text-right px-1 mt-1">
          <v-autocomplete
            dense
            solo
            class="caption"
            :label="$t('select year')"
            item-value="id"
            :items="years"
            v-model="selectedYear"
            @change="onSelectYear(selectedYear)"
          ></v-autocomplete>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4 md4 lg4 class="text-right px-1 mt-1">
          <v-btn dark small class="gradients" @click="printPage()">
            <v-icon small left>mdi-download</v-icon>
            {{ $t("download") }}
          </v-btn>
        </v-flex>
      </v-layout>

      <!-- ####################### NEW payroll ##################### -->

      <div v-show="payroll" class="mt-4">
        <v-card class="grey lighten-3 pa-5">
          <!-- ################## -->
          <div class="body" id="print" style="padding: 30px">
            <div class="container">
              <div class="row center">
                <div class="column">
                  <span class="bold-text">{{ payroll.businessId }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="">{{ building }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="">{{ floor }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="">{{ street }}</span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="">{{ county + " " + country }}</span>
                </div>
              </div>

              <hr class="white-hr" />
              <div class="row center">
                <div class="column">
                  <span class="font-weight-black">{{ title }}</span>
                </div>
              </div>

              <hr class="white-hr" />
              <div class="row left" v-if="employee.name.first">
                <div class="column-half">
                  <span class="bold-text" style="font-size: 15px">
                    {{ $t("name") }}:
                    {{
                      employee.name.first +
                      " " +
                      employee.name.middle +
                      " " +
                      employee.name.last
                    }}
                  </span>
                </div>
              </div>
              <hr class="white-hr" />
              <div class="row left" v-if="selectedYear && selectedMonth">
                <div class="column">
                  <span class="bold-text" style="font-size: 15px">
                    {{ $t("month and year") }}:
                    {{ selectedMonth + " " + selectedYear }}
                  </span>
                </div>
              </div>
              <hr class="black-hr" />
              <hr class="black-hr" />

              <hr class="white-hr" />

              <div class="row left">
                <!-- ########## -->
                <div class="column">
                  <div
                    class="row left"
                    style="margin-top: 5px; font-size: 13px"
                    v-if="employee.basicPay"
                  >
                    <div class="column-half">
                      <span class="bolder-text">{{ $t("basic pay") }}:</span>
                    </div>
                    <div class="column-half left">
                      <span class="bold-text">
                        {{ currencyFormat(employee.basicPay) }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="row left">
                    <div class="column-half">
                      <span class="">{{ $t("total overtime") }}: </span>
                    </div>
                    <div class="column-half">
                      <span class="">{{ currencyFormat(totalOvertime) }}</span>
                    </div>
                  </div>
                </div>

                <!-- ############ -->
                <div class="column" v-if="totalEarnings">
                  <span class="bold-text"> Earnings </span>
                  <div
                    v-for="index in monthlyPayRollEarnings"
                    :key="index.id"
                    style="margin-top: 10px"
                  >
                    <div class="row left">
                      <div class="column-half">
                        <span class="">{{ index.earningsName }}: </span>
                      </div>
                      <div class="column-half">
                        <span class="">{{
                          currencyFormat(index.earningsAmount)
                        }}</span>
                      </div>
                    </div>
                    <hr class="black-hr" />
                  </div>

                  <div
                    class="row left"
                    style="margin-top: 5px; font-size: 13px"
                  >
                    <div class="column-half">
                      <span class="bolder-text">
                        {{ $t("total earnings") }}:</span
                      >
                    </div>
                    <div class="column-half left" v-if="totalEarnings">
                      <span class="bold-text">
                        {{ currencyFormat(totalEarnings) }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div
                    class="row left"
                    style="margin-top: 5px; font-size: 13px"
                    v-if="grossPay"
                  >
                    <div class="column-half">
                      <span class="bolder-text">{{ $t("gross pay") }} :</span>
                    </div>
                    <div class="column-half left">
                      <span class="bold-text">
                        {{ currencyFormat(grossPay) }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="column" v-if="totalDeductions">
                  <span class="bold-text"> {{ $t("deductions") }} </span>
                  <div
                    v-for="index in monthlyPayRollDeductions"
                    :key="index.id"
                    style="margin-top: 10px"
                  >
                    <div class="row left">
                      <div class="column-half">
                        <span class="">{{ index.deductionName }}: </span>
                      </div>
                      <div class="column-half">
                        <span class="">{{
                          currencyFormat(index.deductionAmount)
                        }}</span>
                      </div>
                    </div>
                    <hr class="black-hr" />
                  </div>
                  <div
                    class="row left"
                    style="margin-top: 5px; font-size: 13px"
                  >
                    <div class="column-half">
                      <span class="bolder-text"
                        >{{ $t("total deductions") }}:</span
                      >
                    </div>
                    <div class="column-half" v-if="totalDeductions">
                      <span class="bold-text">
                        {{ currencyFormat(totalDeductions) }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr class="white-hr" />

              <div
                class="row center"
                style="margin-top: 30px"
                v-if="
                  monthlyPayRollEarnings.length == '0' ||
                  monthlyPayRollDeductions.length == '0'
                "
              >
                <div class="column">
                  <p class="bold-text">
                    {{ $t("no earnings or deductions available") }}
                  </p>
                </div>
              </div>
              <!-- ########### -->

              <!-- ############## -->
              <div class="row left border">
                <div class="column-third" v-if="employee.basicPay">
                  <span class="bold-text">
                    {{ $t("basic pay") }}:
                    {{ currencyFormat(employee.basicPay) }}</span
                  >
                </div>
                <div class="column-third" v-if="paye">
                  <span class="bold-text">
                    {{ $t("P.A.Y.E") }}: {{ currencyFormat(paye) }}</span
                  >
                </div>
                <div class="column-third" v-if="netPay">
                  <span class="bold-text">
                    {{ $t("net pay") }}: {{ currencyFormat(netPay) }}</span
                  >
                </div>
              </div>
              <!-- ########### -->
            </div>
          </div>
          <!-- ################## -->
        </v-card>
      </div>
      <!-- ############################### -->

      <v-snackbar
        v-model="snackErrorMonth"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("please also select a month") }}.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorMonth = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorYear"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("please also select a year") }}.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorYear = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { printer } from "@/plugins/functions";
import { format } from "date-fns";

import db from "@/plugins/fb";

export default {
  data: () => ({
    selectedBusiness: "",
    sales: [],
    payroll: [],
    overtimes: [],
    monthSales: [],
    selectedYear: format(new Date(), "yyyy"),
    paye: "",
    netPay: "",
    monthlyPayRollEarnings: [],
    monthlyPayRollDeductions: [],
    selectedMonth: format(new Date(), "MMMM"),
    totalDeductions: "",
    totalEarnings: "",
    building: "",
    floor: "",
    county: "",
    country: "",
    grossPay: "",
    totalOvertime: "",
    street: "",
    snackErrorMonth: false,
    snackErrorYear: false,
    employee: {
      name: {},
    },

    detailedReport: [],
  }),

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    months() {
      return this.$store.getters.getMonths;
    },
    years() {
      return this.$store.getters.getYears;
    },
    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },

    today() {
      return format(new Date(), "yyyy-MM-dd");
    },

    selectedBusinessName() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.businessName;
    },

    selectedBusinessEmail() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.email;
    },
    title() {
      return this.$t("pay slip");
    },
    reportHeader() {
      return [
        { title: this.$t("business name"), value: "" },
        { title: this.$t("email"), value: "" },
        { title: this.$t("date of report"), value: "" },
        { title: this.$t("report period"), value: "" },
      ];
    },

    dataSummary() {
      return [{ title: this.$t("total sales"), value: "" }];
    },
  },

  created() {
    this.getOvertimePay();
    this.getPayrollDetails();
  },

  methods: {
    printer,
    goBack() {
      window.history.back();
    },
    onSelectYear(year) {
      this.selectedYear = year;
      if (!this.selectedMonth) {
        this.snackErrorMonth = true;
      } else {
        this.getOvertimePay();
        this.filterPayroll();
      }
    },

    //function called when month is changed
    onSelectMonth(month) {
      this.selectedMonth = month;
      if (!this.selectedYear) {
        this.snackErrorYear = true;
      } else {
        this.getOvertimePay();
        this.filterPayroll();
      }
    },
    filterPayroll() {
      this.monthlyPayRollEarnings = this.payroll[0].monthlyPay.earnings.filter(
        (item) =>
          format(item.dateTime.toDate(), "MMMM") == this.selectedMonth &&
          format(item.dateTime.toDate(), "yyyy") == this.selectedYear
      );

      this.monthlyPayRollDeductions =
        this.payroll[0].monthlyPay.deductions.filter(
          (item) =>
            format(item.dateTime.toDate(), "MMMM") == this.selectedMonth &&
            format(item.dateTime.toDate(), "yyyy") == this.selectedYear
        );

      let expAmounts = this.monthlyPayRollEarnings.map((item) =>
        Number(item.earningsAmount)
      );
      let expsumAmounts =
        expAmounts.length > 1
          ? expAmounts.reduce((prev, next) => prev + next)
          : expAmounts[0] != null
          ? expAmounts[0]
          : 0;
      this.totalEarnings = expsumAmounts;

      let dedAmounts = this.monthlyPayRollDeductions.map((item) =>
        Number(item.deductionAmount)
      );

      let dedSumAmounts =
        dedAmounts.length > 1
          ? dedAmounts.reduce((prev, next) => prev + next)
          : dedAmounts[0] != null
          ? dedAmounts[0]
          : 0;
      this.totalDeductions = dedSumAmounts;

      this.calculateGrossPay();
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },

    getPayrollDetails() {
      db.collection("payroll")
        .where("employeeId", "==", this.$route.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.payroll.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          this.getBusinessName(this.payroll[0].businessId);
          this.getEmployeeDetails(this.payroll[0]);
        });
    },
    getOvertimePay() {
      this.overtimes = [];
      this.totalOvertime = 0;
      db.collection("overtimes")
        .where("employeeId", "==", this.$route.params.id)

        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.overtimes.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          let filteredOvertimes = this.overtimes.filter(
            (item) =>
              format(item.overtimeDate.toDate(), "MMMM") ==
                this.selectedMonth &&
              format(item.overtimeDate.toDate(), "yyyy") == this.selectedYear
          );

          let overtimeAmount = filteredOvertimes.map((item) =>
            Number(item.overtimePay)
          );

          let overtimeSumAmount =
            overtimeAmount.length > 1
              ? overtimeAmount.reduce((prev, next) => prev + next)
              : overtimeAmount[0] != null
              ? overtimeAmount[0]
              : 0;
          this.totalOvertime = overtimeSumAmount;
        });
    },

    getEmployeeDetails(data) {
      this.employee.name.first = data.names.first;
      this.employee.name.middle = data.names.middle;
      this.employee.name.last = data.names.last;
      this.employee.basicPay = data.basicPay;
      this.employee.paye = data.paye;

      this.filterPayroll();
    },

    calculateGrossPay() {
      this.grossPay =
        Number(this.employee.basicPay) +
        this.totalEarnings +
        this.totalOvertime;
      this.calculateNetPay();
    },

    calculateNetPay() {
      //reset variables

      this.paye = "";
      this.netPay = "";
      this.paye = Math.round(
        (this.employee.paye / 100) *
          (Number(this.grossPay) + this.totalEarnings - this.totalDeductions)
      );

      this.netPay = Math.round(
        Number(this.grossPay) +
          this.totalEarnings -
          (this.totalDeductions + this.paye)
      );
    },

    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );

      this.getBusinessLocation(id);
      return bizId.businessName;
    },

    getBusinessLocation(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );

      this.building = bizId.address.building;
      this.floor = bizId.address.no;
      this.county = bizId.address.county;
      this.country = bizId.address.country;
      this.street = bizId.address.street;
    },

    printPage() {
      this.printer("print", "payroll");
    },
    formatDate(val) {
      return format(val, "yyyy-MM-dd");
    },

    formatDateMinuteTime(val) {
      return format(val, "hh:mm aa");
    },
  },
};
</script>

<style scoped>
.printOut {
  border: 1px solid #eee;
  min-height: 29.7cm;
  width: 21cm;
  padding: 2cm;
  background: #fff;
}
.payrollContents {
  font-family: "Courier New", monospace;
}
.headingRow {
  font-size: 13px;
  color: #aa9494;
  background-color: black;
}
.captionStyle {
  font-size: 13px;
}

.white-hr {
  border: 1px dashed white;
  background-color: white;
}
.black-hr {
  border-top: 1px dashed #000000;
  height: 1px;
  border-radius: 5px;
}
.black-background {
  background-color: #000000;
}
.white-text {
  color: white;
}

.center {
  text-align-last: center;
}
.left {
  text-align-last: left;
}

.right {
  text-align-last: right;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.border {
  margin-top: 35px;
  border-style: dotted;
}

.body {
  font-family: "Courier New", monospace;
  padding: 20px;
}
.body .container {
  background: white;
  color: grey (126, 126, 126);
  font-size: 12px;
  padding: 40px;
}
.bold-text {
  font-weight: bold;
}
.bolder-text {
  font-weight: bolder;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
  margin-top: 2px;
}

.column-half {
  float: left;
  width: 50%;
  padding: 10px;
}

.column-third {
  float: left;
  width: 33.33%;
  padding: 10px;

  margin-top: 4px;
}

.column-two-third {
  float: left;
  width: 66.66%;
  padding: 10px;
}

.column-quarter {
  float: left;
  width: 25%;
  padding: 10px;
  margin-top: 2px;
}

.column-three-quarter {
  float: left;
  width: 75%;
  padding: 10px;
}
</style>
